<template>
    <div
        v-if="content"
        class="component component--collection text-image"
        :class="classes"
    >
        <ItemGrid
            class="item-grid--mb"
            data-columns-xs="1"
            data-columns-sm="1"
            data-columns-md="2"
            data-columns-lg="2"
            data-columns-xl="2"
        >
            <figure
                v-if="src && leftRight === ALIGNED_LEFT"
                class="item-grid__item"
            >
                <img
                    :src="src"
                    :alt="alt"
                    :title="title"
                    :loading="loading"
                >
                <figcaption v-if="caption">{{ caption }}</figcaption>
            </figure>

            <HtmlContent class="item-grid__item">
                <BaseArticle>
                    <div v-html="content.wysiwyg" /> <!-- eslint-disable-line vue/no-v-html -->
                </BaseArticle>

                <template v-if="linkBlock">
                    <NavLink
                        :type="mapType(linkBlock.__typename)"
                        :target="mapTarget(linkBlock.targetBlank)"
                        :url="mapURI(linkBlock)"
                        :icon="linkBlock.icon"
                        classes="button button--primary"
                        data-spacing-top="xxl"
                        data-spacing-bottom="md"
                    >
                        {{ linkBlock.titel }}
                    </NavLink>
                </template>
            </HtmlContent>

            <div class="item-grid__item">
                <figure v-if="src && leftRight !== ALIGNED_LEFT">
                    <img
                        :src="src"
                        :alt="alt"
                        :title="title"
                        :loading="loading"
                    >
                    <figcaption v-if="caption">{{ caption }}</figcaption>
                </figure>
            </div>
        </ItemGrid>
    </div>
</template>

<script>
import Navigation from '~/mixins/navigation';

export const ALIGNED_RIGHT = false;
export const ALIGNED_LEFT = true;

export default {
    mixins: [
        Navigation
    ],
    props: {
        content: {
            type: Object,
            required: true,
            default: () => { return {}; }
        },
        loading: {
            type: String,
            default: 'lazy'
        }
    },
    data() {
        return {
            ALIGNED_LEFT,
            ALIGNED_RIGHT
        };
    },
    computed: {
        src() {
            return this.content?.image?.[0]?.url ?? '';
        },
        alt() {
            return this.content?.image?.[0]?.alt ?? '';
        },
        title() {
            return this.content?.image?.[0]?.title ?? '';
        },
        caption() {
            return this.content?.caption;
        },
        leftRight() {
            return this.content?.leftRight;
        },
        wysiwyg() {
            return this.content?.wysiwyg;
        },
        linkBlock() {
            return this.content?.linkBlock?.[0] ?? null;
        },
        classes() {
            const background = this.content?.background ?? 'color-lightest';
            let classes = '';

            if (background) {
                classes += `background background--bleed background--${background}`;
            } else {
                classes += 'background background--bleed background--color-lightest';
            }

            if (this.src) {
                if (this.leftRight === ALIGNED_LEFT) {
                    classes += ' text-image--image-left';
                }
                classes += ' text-image--image-right';
            }

            return classes;
        },
    }
};
</script>

<style src="./text-image.less" lang="less" scoped />
