<template>
    <div :class="classes">
        <BaseFaq :questions="content.faq" />
    </div>
</template>

<script setup>
const props = defineProps({
    content: {
        type: Object,
        required: true,
        default: () => {
            return {
                faq: []
            };
        }
    }
});

const { content } = toRefs(props);

const classes = computed(() => {
    const background = content.value?.background ?? 'color-lightest';

    if (background) {
        return `background background--bleed background--${background}`;
    }

    return 'background background--bleed background--color-lightest';
});
</script>
