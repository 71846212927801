<template>
    <div
        v-if="imageSrc"
        class="card-simple"
    >
        <div class="card-simple__image">
            <figure>
                <img
                    :src="imageSrc"
                    :alt="title"
                    :title="title"
                    :loading="loading"
                >
            </figure>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    image: {
        type: Array,
        default: () => []
    },
    title: {
        type: String,
        default: ''
    },
    loading: {
        type: String,
        default: 'lazy'
    }
});

const { image } = toRefs(props);

const imageSrc = computed(() => image.value?.[0]?.url);
</script>

<style src="./Simple.less" lang="less" scoped />
