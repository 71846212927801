<template>
    <div
        class="component component--collection cases-block"
        :class="classes"
    >
        <BaseArticle
            v-if="wysiwyg"
            class="subcomponent subcomponent--content"
            data-spacing-bottom="xxxl"
        >
            <h3 v-if="header">{{ header }}</h3>
            <div v-html="wysiwyg" /> <!-- eslint-disable-line vue/no-v-html -->
        </BaseArticle>

        <CollectionCases
            v-if="content && cases"
            :cases
        />
    </div>
</template>

<script setup>
const props = defineProps({
    content: {
        type: Object,
        required: true,
        default: () => { return { cases: [] }; }
    },
    loading: {
        type: String,
        required: false,
        default: 'lazy'
    }
});

const { content } = toRefs(props);

const cases = computed(() => content.value?.cases ?? []);
const header = computed(() => content.value?.header ?? null);
const wysiwyg = computed(() => content.value?.wysiwyg ?? null);
const classes = computed(() => {
    const background = content.value?.background ?? 'color-lightest';

    if (background) {
        return `background background--bleed background--${background}`;
    }

    return 'background background--bleed background--color-lightest';
});
</script>
