<template>
    <div
        class="component component--collection"
        :class="classes"
    >
        <BaseArticle
            v-if="wysiwyg"
            class="subcomponent subcomponent--content subcomponent--center"
            data-spacing-bottom="xxl"
        >
            <div v-html="wysiwyg" /> <!-- eslint-disable-line vue/no-v-html -->
        </BaseArticle>

        <CollectionImages :images />
    </div>
</template>

<script setup>
const props = defineProps({
    content: {
        type: Object,
        required: true,
        default: () => { return {}; }
    },
    loading: {
        type: String,
        default: 'lazy'
    }
});

const { content } = toRefs(props);

const wysiwyg = computed(() => content.value?.wysiwyg ?? null);
const images = computed(() => content.value?.images ?? null);
const classes = computed(() => {
    const background = content.value?.background ?? 'color-lightest';

    if (background) {
        return `background background--bleed background--${background}`;
    }

    return 'background background--bleed background--color-lightest';
});
</script>
