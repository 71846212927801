<template>
    <div
        class="component"
        :class="classes"
    >
        <BaseArticle
            class="subcomponent subcomponent--content subcomponent--center"
            data-spacing-bottom="xxxl"
        >
            <h2 v-if="header">{{ header }}</h2>
            <template v-if="linkBlock">
                <NavLink
                    :type="mapType(linkBlock.__typename)"
                    :target="mapTarget(linkBlock.targetBlank)"
                    :url="mapURI(linkBlock)"
                    classes="button button--primary"
                    :icon="linkBlock.icon"
                >
                    {{ linkBlock.titel }}
                </NavLink>
            </template>
        </BaseArticle>

        <SliderContinuous :items="projectsOrPortfolios" />
    </div>
</template>

<script setup>
const props = defineProps({
    content: {
        type: Object,
        required: true,
        default: () => { return { cases: [] }; }
    }
});

const { content } = toRefs(props);

const projectsOrPortfolios = computed(() => content.value?.projectsOrPortfolios ?? []);
const header = computed(() => content.value?.header ?? null);
const linkBlock = computed(() => content.value?.linkBlock?.[0] ?? null);
const classes = computed(() => {
    const background = content.value?.background ?? 'color-lightest';

    if (background) {
        return `background background--bleed background--${background}`;
    }

    return 'background background--bleed background--color-lightest';
});
</script>

<script>
import Navigation from '~/mixins/navigation';

export default {
    mixins: [
        Navigation
    ],
};
</script>
