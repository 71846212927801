<template>
    <div
        v-if="content"
        class="component component--collection"
        :class="classes"
    >
        <h2
            v-if="header"
            class="subcomponent subcomponent--content subcomponent--center"
            data-spacing-bottom="xxl"
        >
            {{ header }}
        </h2>

        <ItemGrid
            v-if="!showAsSlider"
            class="item-grid--mb"
            data-columns-xs="2"
            data-columns-sm="3"
            data-columns-md="3"
            data-columns-lg="4"
            data-columns-xl="4"
        >
            <TeamMember
                v-for="(teammember, index) in team"
                :key="index"
                :content="teammember"
                class="item-grid__item"
            />
        </ItemGrid>

        <SliderContinuous
            v-else
            :items="team"
            data-spacing-bottom="xxl"
        />
    </div>
</template>

<script setup>
import TeamMember from './teammember.vue';

const props = defineProps({
    content: {
        type: Object,
        required: true,
        default: () => { return { team: [] }; }
    }
});

const { content } = toRefs(props);

const team = computed(() => content.value?.team ?? []);
const header = computed(() => content.value?.header ?? null);
const showAsSlider = computed(() => content.value?.showAsSlider ?? null);
const classes = computed(() => {
    const background = content.value?.background ?? 'color-lightest';

    if (background) {
        return `background background--bleed background--${background}`;
    }

    return 'background background--bleed background--color-lightest';
});
</script>
