<template>
    <div :class="classes">
        <div class="subcomponent subcomponent--content">
            <EmbedVideo
                :url
                :caption
            />
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    content: {
        type: Object,
        required: true,
        default: () => {
            return {};
        }
    }
});

const { content } = toRefs(props);

const url = computed(() => content.value?.url);
const caption = computed(() => content.value?.caption);
const classes = computed(() => {
    const background = content.value?.background ?? 'color-lightest';

    if (background) {
        return `background background--bleed background--${background}`;
    }

    return 'background background--bleed background--color-lightest';
});
</script>
