<template>
    <div
        class="link-block"
        :class="classes"
    >
        <h3
            v-if="content.header"
            class="link-block__title"
        >
            {{ content.header }}
        </h3>

        <div
            v-for="(link, index) in content.linkBlock"
            :key="index"
        >
            <NavLink
                :type="mapType(link.__typename)"
                :url="mapURI(link)"
                :target="mapTarget(link.targetBlank)"
                class="link-block__link"
                :classes="content.looksLikeButton ? 'button button--primary button--icon-right' : 'link-block__link'"
                :icon="link.icon"
            >
                {{ link.titel }}
            </NavLink>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    content: {
        type: Object,
        required: true,
        default: () => {
            return {
                header: '',
                linkBlock: []
            };
        }
    }
});

const { content } = toRefs(props);

const classes = computed(() => {
    const background = content.value?.background ?? 'color-lightest';

    if (background) {
        return `background background--bleed background--${background}`;
    }

    return 'background background--bleed background--color-lightest';
});
</script>

<script>
import Navigation from '~/mixins/navigation';

export default {
    mixins: [
        Navigation
    ],
};
</script>

<style src="./link-block.less" lang="less" scoped />
