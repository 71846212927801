<template>
    <NuxtLink
        v-if="!isVideo"
        :to="url"
        class="card-news"
    >
        <div class="card-news__image">
            <figure v-if="imageSrc">
                <img
                    :src="imageSrc"
                    :alt="imageAlt"
                    :title="imageTitle"
                    :loading="loading"
                >
            </figure>
        </div>

        <div class="card-news__info">
            <span
                v-if="card.postDate"
                class="card-news__info__date"
            >
                {{ localisedDate(card.postDate) }}
            </span>
            <span
                v-if="category"
                class="card-news__info__category"
            >
                {{ category }}
            </span>
        </div>
        <h4
            v-if="title"
            class="card-news__title"
        >
            {{ title }}
        </h4>
    </NuxtLink>
    <a
        v-else
        :href="url"
        class="card-news card-news--video"
        @click.stop.prevent.capture="onItemClick"
    >
        <div class="card-news__image">
            <figure v-if="imageSrc">
                <img
                    :src="imageSrc"
                    :alt="imageAlt"
                    :title="imageTitle"
                    :loading="loading"
                >
            </figure>
        </div>

        <div class="card-news__info">
            <span
                v-if="card.postDate"
                class="card-news__info__date"
            >
                {{ localisedDate(card.postDate) }}
            </span>
            <span
                v-if="category"
                class="card-news__info__category"
            >
                {{ category }}
            </span>
        </div>
        <h4
            v-if="title"
            class="card-news__title"
        >
            {{ title }}
        </h4>
    </a>
</template>

<script setup>
const props = defineProps({
    card: {
        type: Object,
        required: true,
        default: () => { return {}; }
    },
    loading: {
        type: String,
        default: 'lazy'
    }
});

const { card } = toRefs(props);

const localePath = useLocalePath();

const title = computed(() => card.value?.title);
const category = computed(() => card.value?.newsCategory?.[0]?.title);
const image = computed(() => card.value?.cardImage?.[0] ?? card.value?.featuredImage?.[0] ?? {});
const imageSrc = computed(() => image.value?.url ?? '');
const imageAlt = computed(() => image.value?.alt ?? '');
const imageTitle = computed(() => image.value?.title ?? '');
const url = computed(() => localePath({ name: 'nieuws-slug', params: { slug: card.value?.slug }}));
const isVideo = computed(() => card.value?.__typename === 'news_video_Entry');

const router = useRouter();
const route = useRoute();
const emit = defineEmits(['open-video']);

/**
 * Hijack the click event on the item link, so we can change the url and open the modal ourselves. This way
 * we stay on this page and algolia doesn't need to reload and mess with the url history. But the full url
 * works the same, the modal will be loaded with the search / index on the background.
 */
const onItemClick = () => {
    const resolved = router.resolve(
        url.value,
        route,
        false
    );

    history.pushState(
        {},
        title.value,
        resolved.href,
    );

    emit('open-video', card.value);
};
</script>

<script>
import Navigation from '~/mixins/navigation';

export default {
    mixins: [
        Navigation
    ]
};
</script>

<style src="./News.less" lang="less" scoped />
