<template>
    <div
        class="component component--spacious"
        :class="classes"
    >
        <ProminentText
            v-if="header"
            :header="header"
            :show-lotteries="showLotteries"
            class="prominent-text"
        />
    </div>
</template>

<script setup>
const props = defineProps({
    content: {
        type: Object,
        required: true,
        default: () => { return {}; }
    }
});

const { content } = toRefs(props);

const header = computed(() => content.value?.header ?? null);
const showLotteries = computed(() => content.value?.showLotteries ?? null);
const classes = computed(() => {
    const background = content.value?.background ?? 'color-lightest';

    if (background) {
        return `background background--bleed background--${background}`;
    }

    return 'background background--bleed background--color-lightest';
});
</script>
