<template>
    <ItemGrid
        class="item-grid--mb-2x"
        data-columns-xs="1"
        data-columns-sm="1"
        data-columns-md="3"
        data-columns-lg="3"
        data-columns-xl="3"
    >
        <div
            v-for="(card, index) in cards"
            :key="index"
            class="item-grid__item"
        >
            <CardNews
                :card="card"
                @open-video="openVideo"
            />
        </div>
    </ItemGrid>
</template>

<script setup>
defineProps({
    cards: {
        type: Array,
        required: true,
        default: () => {
            return [];
        }
    }
});

const emit = defineEmits(['open-video']);
const openVideo = (item) => {
    emit('open-video', item);
};
</script>
