<template>
    <div class="faq">
        <div
            v-for="(question, i) in questions"
            :key="`faq-item${i}`"
            :data-active="open === i"
            class="faq__item"
        >
            <button
                class="faq__question"
                @click="toggleQuestion(i)"
            >
                <h4>
                    {{ question.question }}
                </h4>

                <BoomerIcon>
                    <IconAdd />
                </BoomerIcon>
            </button>

            <!-- eslint-disable vue/no-v-html -->
            <div
                v-show="open === i"
                class="faq__answer"
                v-html="question.answer"
            />
            <!-- eslint-enable vue/no-v-html -->
        </div>
    </div>
</template>

<script setup>
import IconAdd from '~/components/_icons/add.vue';

defineProps({
    questions: {
        type: Array,
        required: true,
        default() {
            return [];
        }
    }
});

const open = ref(null);

const toggleQuestion = (questionNumber) => {
    if (open.value === toValue(questionNumber)) {
        open.value = null;
    } else {
        open.value = toValue(questionNumber);
    }
};
</script>

<style lang="less" src="./BaseFaq.less" scoped />
