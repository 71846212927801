<template>
    <NavLink
        v-if="content"
        :type="mapType(linkBlock.__typename)"
        :target="mapTarget(linkBlock.targetBlank)"
        :url="mapURI(linkBlock)"
        :icon="linkBlock.icon"
        class="link-card"
    >
        <div class="link-card__image">
            <figure v-if="imageSrc">
                <img
                    :src="imageSrc"
                    :alt="imageAlt"
                    :title="imageTitle"
                    :loading="loading"
                >
            </figure>
        </div>

        <h4 class="link-card__title"><BoomerIcon v-if="simple"><IconExternal /></BoomerIcon>{{ header }}</h4>

        <HtmlContent v-if="content?.wysiwyg && !simple">
            <BaseArticle>
                <div v-html="content.wysiwyg" /> <!-- eslint-disable-line vue/no-v-html -->
            </BaseArticle>
        </HtmlContent>
    </NavLink>
</template>

<script setup>
import IconExternal from '~/components/_icons/external.vue';

const props = defineProps({
    content: {
        type: Object,
        required: true,
        default: () => { return {}; }
    },
    loading: {
        type: String,
        default: 'lazy'
    },
    simple: {
        type: Boolean,
        default: false,
        required: false
    }
});

const { content } = toRefs(props);

const header = computed(() => content.value?.header);
const image = computed(() => content.value?.image?.[0] ?? {});
const imageSrc = computed(() => image.value?.url);
const imageAlt = computed(() => image.value?.alt);
const imageTitle = computed(() => image.value?.title);
const linkBlock = computed(() => content.value?.linkUrl?.[0] ?? {});
</script>

<script>
import Navigation from '~/mixins/navigation';

export default {
    mixins: [
        Navigation
    ]
};
</script>

<style src="./link-card.less" lang="less" scoped />
