<template>
    <div
        v-if="content"
        class="component component--collection"
        :class="classes"
    >
        <ItemGrid
            class="item-grid--mb"
            data-columns-xs="1"
            data-columns-sm="2"
            data-columns-md="3"
            data-columns-lg="3"
            data-columns-xl="3"
        >
            <LinkCard
                v-for="(card, index) in cards"
                :key="index"
                :content="card"
                class="item-grid__item"
                :simple="simpleLayout"
            />
        </ItemGrid>
    </div>
</template>

<script setup>
import LinkCard from './link-card.vue';

const props = defineProps({
    content: {
        type: Object,
        required: true,
        default: () => { return { cards: [] }; }
    }
});

const { content } = toRefs(props);

const cards = computed(() => content.value?.cards ?? []);
const layout = computed(() => content.value?.layout ?? '');
const simpleLayout = computed(() => layout.value === 'simple');
const classes = computed(() => {
    const background = content.value?.background ?? 'color-lightest';
    const _layout = simpleLayout.value ? 'component--link-cards--layout-simple' : 'component--link-cards--layout-cards';
    return `background background--bleed background--${background} ${_layout}`;
});
</script>

<style scoped>
.component--link-cards--layout-simple .item-grid {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
}
</style>
