import { format } from 'date-fns';
import { nl } from 'date-fns/locale';

export const localisedDate = (val, dateFormat = 'dd LLLL yyyy') => {
    const { locale } = useI18n();

    const newDate = new Date(val);

    if (locale.value === 'nl') {
        return newDate ? format(newDate, dateFormat, { locale: nl }) : '';
    }

    return newDate ? format(newDate, dateFormat) : '';
};
