<template>
    <div
        v-if="themeCategory"
        class="component component--collection programmes-block"
        :class="classes"
    >
        <BaseArticle>
            <h3>
                <IconBlock
                    v-if="isDoen && themeSlug"
                    :name="themeSlug"
                    class="programmes-block__icon"
                />
                <span class="programmes-block__title">{{ themeTitle }}</span>
            </h3>

            <HtmlContent>
                <div v-html="content.wysiwyg" /> <!-- eslint-disable-line vue/no-v-html -->
            </HtmlContent>
        </BaseArticle>

        <ItemGrid
            class="item-grid--mb"
            data-spacing-top="xxxl"
            data-columns-xs="1"
            data-columns-sm="2"
            data-columns-md="3"
            data-columns-lg="3"
            data-columns-xl="3"
        >
            <CardBase
                v-for="programCategory in programCategories"
                :key="programCategory.id"
                :url="localePath({ name: 'wat-we-doen-program', params: { program: programCategory.slug } })"
                :header="programCategory.title"
                :wysiwyg="programCategory.wysiwyg"
                :image="programCategory.image[0]"
                :program-category="programCategory"
                class="item-grid__item programmes-block__card"
            />
        </ItemGrid>
    </div>
</template>

<script setup>
import getProgramCategories from '~/graphql/queries/ProgramCategories.graphql';

const props = defineProps({
    content: {
        type: Object,
        required: true,
        default: () => { return { themeCategory: [], wyswiyg: '' }; }
    }
});

const { content } = toRefs(props);

const localePath = useLocalePath();
const { isDoen } = useMultisite();

const themeCategory = computed(() => content.value?.themeCategory ?? []);
const themeSlug = computed(() => themeCategory.value?.[0]?.slug ?? '');
const themeTitle = computed(() => themeCategory.value?.[0]?.title ?? '');
const themeCategoryIds = computed(() => themeCategory.value.map(({ id }) => id));
const classes = computed(() => {
    const background = content.value?.background ?? 'color-lightest';

    if (background) {
        return `background background--bleed background--${background}`;
    }

    return 'background background--bleed background--color-lightest';
});

const { variables: defaultVariables } = useDefaultVariables();
const variables = computed(() => {
    return {
        ...defaultVariables.value,
        themeCategory: themeCategoryIds.value,
    };
});

const { data } = await useAsyncQuery({
    query: getProgramCategories,
    variables
});

const programCategories = computed(() => data.value?.categories ?? []);
</script>

<style lang="less" src="./programmes-block.less" scoped />
