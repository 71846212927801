<template>
    <div
        v-if="content"
        class="component component--teammember-block team-member"
    >
        <div class="team-member__image">
            <figure v-if="photoSrc">
                <img
                    :src="photoSrc"
                    :alt="photoAlt"
                    :title="photoTitle"
                    :loading="loading"
                >
            </figure>
        </div>
        <h4 class="team-member__name">{{ memberTitle }}</h4>
        <p class="team-member__function">{{ memberFunction }}</p>
    </div>
</template>

<script setup>
const props = defineProps({
    content: {
        type: Object,
        required: true,
        default: () => { return { team: [] }; }
    },
    loading: {
        type: String,
        default: 'lazy'
    }
});

const { content } = toRefs(props);

const photo = computed(() => content.value?.photo?.[0] ?? {});
const photoSrc = computed(() => photo.value?.url ?? '');
const photoAlt = computed(() => photo.value?.alt ?? '');
const photoTitle = computed(() => photo.value?.title ?? '');
const memberTitle = computed(() => content.value?.title ?? '');
const memberFunction = computed(() => content.value?.function ?? '');
</script>

<style src="./team-member.less" lang="less" scoped />
