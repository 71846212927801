<template>
    <HtmlContent :class="classes">
        <BaseArticle class="subcomponent subcomponent--content">
            <div v-html="content.wysiwyg_main" /> <!-- eslint-disable-line vue/no-v-html -->
        </BaseArticle>
    </HtmlContent>
</template>

<script setup>
const props = defineProps({
    content: {
        type: Object,
        required: true,
        default: () => { return {}; }
    }
});

const { content } = toRefs(props);

const classes = computed(() => {
    const background = content.value?.background ?? 'color-lightest';

    if (background) {
        return `background background--bleed background--${background}`;
    }

    return 'background background--bleed background--color-lightest';
});
</script>
