<template>
    <NavLink
        v-if="url"
        :url="url"
        class="card"
    >
        <div class="card__image">
            <figure v-if="imageObject && imageSrc">
                <img
                    :src="imageSrc"
                    :alt="imageAlt"
                    :title="imageTitle"
                    :loading="loading"
                >
            </figure>

            <IconBlock
                v-if="programSlug || themeSlug"
                :name="programSlug || themeSlug"
                class="card__image__icon"
            />
        </div>

        <h4
            v-if="header"
            class="card__title"
        >
            {{ header }}
        </h4>

        <HtmlContent v-if="wysiwyg">
            <BaseArticle>
                <div v-html="wysiwyg" /> <!-- eslint-disable-line vue/no-v-html -->
            </BaseArticle>
        </HtmlContent>
    </NavLink>
</template>

<script setup>
const props = defineProps({
    image: {
        type: Object,
        default: () => {}
    },
    header: {
        type: String,
        default: ''
    },
    url: {
        type: String,
        required: true
    },
    wysiwyg: {
        type: String,
        default: ''
    },
    programCategory: {
        type: Object,
        default: () => {}
    },
    loading: {
        type: String,
        default: 'lazy'
    }
});

const { image, programCategory } = toRefs(props);

const imageObject = computed(() => image.value ?? '');
const imageSrc = computed(() => imageObject.value?.url ?? '');
const imageAlt = computed(() => imageObject.value?.alt ?? '');
const imageTitle = computed(() => imageObject.value?.title ?? '');
const theme = computed(() => programCategory.value?.themeCategory?.[0] ?? '');
const themeSlug = computed(() => theme.value?.slug ?? '');
const programSlug = computed(() => programCategory.value?.slug ?? '');
</script>

<script>
import Navigation from '~/mixins/navigation';

export default {
    mixins: [
        Navigation
    ],
};
</script>

<style src="./Base.less" lang="less" scoped />
