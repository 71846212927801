<template>
    <div :class="classes">
        <figure
            v-if="src"
            class="subcomponent subcomponent--content"
        >
            <img
                :src="src"
                :alt="alt"
                :title="title"
                :loading="loading"
            >
            <figcaption v-if="caption">{{ caption }}</figcaption>
        </figure>
    </div>
</template>

<script setup>
const props = defineProps({
    content: {
        type: Object,
        required: true,
        default: () => { return {}; }
    },
    loading: {
        type: String,
        default: 'lazy'
    }
});

const { content } = toRefs(props);

const src = computed(() => content.value?.fullwidthimage_main?.[0]?.url ?? '');
const alt = computed(() => content.value?.fullwidthimage_main?.[0]?.alt ?? '');
const title = computed(() => content.value?.fullwidthimage_main?.[0]?.title ?? '');
const caption = computed(() => content?.value?.caption ?? '');
const classes = computed(() => {
    const background = content.value?.background ?? 'color-lightest';

    if (background) {
        return `background background--bleed background--${background}`;
    }

    return 'background background--bleed background--color-lightest';
});
</script>
