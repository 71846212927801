<template>
    <ItemGrid
        class="item-grid--mb"
        data-columns-xs="1"
        data-columns-sm="2"
        data-columns-md="3"
        data-columns-lg="3"
        data-columns-xl="3"
    >
        <CardBase
            v-for="projectCase in cases"
            :key="projectCase.id"
            :url="localePath({ name: 'wat-we-doen-program-slug', params: { program: getProgramSlug(projectCase), slug: projectCase.slug } })"
            :header="projectCase.title"
            :wysiwyg="projectCase.cardWysiwyg"
            :image="projectCase.cardImage[0]"
            :program-category="projectCase.programCategory[0]"
            class="item-grid__item"
        />
    </ItemGrid>
</template>

<script setup>
defineProps({
    cases: {
        type: Array,
        required: true,
        default: () => { return []; }
    }
});

const localePath = useLocalePath();

const getProgramSlug = (projectCase) => projectCase?.programCategory?.[0]?.slug ?? '';
</script>
