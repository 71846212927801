<template>
    <div
        class="component component--collection-large news-block"
        :class="classes"
    >
        <h3
            class="news-block__title"
            data-spacing-bottom="xxl"
        >
            <span v-if="header">{{ header }}</span>
            <span v-else>{{ t('news.title') }}</span>

            <NuxtLink
                v-if="!hideMoreNews"
                :to="localePath({ name: 'nieuws-slug' })"
                class="news-block__more button button--tertiary button--icon-right"
            >
                {{ t('news.more_news') }}
                <span class="button__icon">
                    <BoomerIcon><icon-chevron-right-large /></BoomerIcon>
                </span>
            </NuxtLink>
        </h3>

        <BaseArticle
            v-if="wysiwyg"
            class="subcomponent subcomponent--content"
            data-spacing-bottom="xxxl"
        >
            <div v-html="wysiwyg" /> <!-- eslint-disable-line vue/no-v-html -->
        </BaseArticle>

        <CollectionNews
            v-if="content"
            :cards="news"
            @open-video="openVideo"
        />

        <Transition name="modal-popup">
            <ModalPopup
                v-if="videoUrl"
                class="modal-popup--video animated fadeIn fast"
                @close="closeVideo"
            >
                <template #image>
                    <EmbedVideo
                        :url="videoUrl"
                        :caption="videoCaption"
                    />
                </template>
            </ModalPopup>
        </Transition>
    </div>
</template>

<script setup>
import IconChevronRightLarge from '~/components/_icons/chevron-right-large.vue';

const props = defineProps({
    content: {
        type: Object,
        required: true,
        default: () => { return { news: [] }; }
    }
});

const { content } = toRefs(props);

const { t } = useI18n();

const video = ref(null);
const router = useRouter();
const localePath = useLocalePath();

const news = computed(() => content.value?.news ?? []);
const header = computed(() => content.value?.header ?? null);
const wysiwyg = computed(() => content.value?.wysiwyg ?? null);
const hideMoreNews = computed(() => content.value?.hideMoreNews ?? null);
const videoUrl = computed(() => video.value?.simpleUrl ?? '');
const videoCaption = computed(() => video.value?.videoCaption ?? '');
const classes = computed(() => {
    const background = content.value?.background ?? 'color-lightest';

    if (background) {
        return `background background--bleed background--${background}`;
    }

    return 'background background--bleed background--color-lightest';
});

const openVideo = (item) => {
    video.value = item;
};

const closeVideo = () => {
    if (video.value) {
        video.value = null;
        router.go(-1);
    }
};
</script>

<style lang="less" src="./news-block.less" scoped />
